#iframe-widget-external-system-contacts-by-ai {
    display: none;
    z-index: 9999;
    position: fixed;
}
#iframe-widget-external-system-contacts-by-ai.show-widget-system-contect-by-ai-external-busswe {
    display: unset !important;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: unset;
    bottom: unset;
}
#iframe-widget-external-system-contacts-by-ai.hide-widget-system-contect-by-ai-external-busswe {
    display: none !important;
    width: 1px;
    height: 1px;
    left: unset;
    top: unset;
    right: 0;
    bottom: 0;
}

.btn-option-widget-external-system-contact-by-ai.shake-button {
    /* Start the shake animation and make the animation last for 0.5 seconds */
    animation: shake 0.5s;

    /* When the animation is finished, start again */
    animation-iteration-count: infinite;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}